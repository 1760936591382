<template>
  <component
    :is="`h${level}`"
    :class="classes"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import './Heading.css'
import { defineComponent, toRefs, computed } from 'vue'
import type { PropType } from 'vue'

export type HeadingSize = '6xl' | '5xl' | '4xl' | '3xl' | '2xl' | 'xl' | 'lg' | 'base' | 'sm' | 'xs'
export type HeaderLevel = '1' | '2' | '3' | '4' | '5' | '6' | 1 | 2 | 3 | 4 | 5 | 6

export default defineComponent({
  name: 'GlobalAHeading',
  props: {
    level: {
      type: [String, Number] as PropType<HeaderLevel>,
      required: true
    },
    size: {
      type: String as PropType<HeadingSize | ''>,
      default: ''
    }
  },
  setup (props) {
    const { size } = toRefs(props)

    const classes = computed(() => {
      const list = ['heading']

      if (size.value !== '') { list.push(`heading--${size.value}`) }

      return list.join(' ')
    })

    return { classes }
  }
})
</script>
